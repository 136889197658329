import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Single Arm DB Push Press 3×12/arm`}</p>
    <p>{`Band Face Pulls 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`15-DB S2OH (35’s/20’s)`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`15-Ring Rows`}</p>
    <p>{`60-Single Unders`}</p>
    <p><strong parentName="p">{`*`}{`This Friday from 3-? Bluegrass Bodyfat Testing will be set up to do
body fat tests for just \\$30 (\\$25 for GG competitors)!  This is a
\\$15-\\$20 savings off their normal prices so take advantage of this
deal!  You will get wet so bring swimwear or workout clothes and a
towel. `}<a parentName="strong" {...{
          "href": "http://www.bluegrassbftest.com"
        }}>{`http://www.bluegrassbftest.com`}</a></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Friday’s 6pm class will be cancelled so that we can set up for
our Granite Games Throwdown.  Any help with setting up would be greatly
appreciated!`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`This Saturday is our Granite Games Fall Throwdown!  Come out and
cheer on the teams competing and check out our Vendors!  First heat
starts at 9:00am.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      